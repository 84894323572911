import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";

Vue.use(Router);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    // This is for the scroll top when click on any router link
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0;

        if (to.hash) {
            scrollTo = to.hash;
        } else if (savedPosition) {
            scrollTo = savedPosition.y;
        }

        return goTo(scrollTo);
    },

    // This is for the scroll top when click on any router link
    routes: [{
            path: "",
            redirect: "homepage",
            component: () =>
                import ("@/layouts/user-layout/FullLayout.vue"),
            children: [{
                    name: "homepage",
                    path: "homepage",
                    component: () =>
                        import ("@/views/user/Home.vue"),
                },

                {
                    name: "contact",
                    path: "contact",
                    component: () =>
                        import ("@/views/user/Contact.vue"),
                },
                {
                    name: "course",
                    path: "course",
                    component: () =>
                        import ("@/views/user/Courses.vue"),
                },
                {
                    name: "manual",
                    path: "manual",
                    component: () =>
                        import ("@/views/user/Manual.vue"),
                },
                {
                    name: "teacher",
                    path: "teacher",
                    component: () =>
                        import ("@/views/user/Teachers.vue"),
                },
            ],
        },
        {
            path: "",
            component: () =>
                import ("@/layouts/user-layout/Blanklayout"),
            children: [{
                    name: "loginuser",
                    path: "login",
                    component: () =>
                        import ("@/views/user/Login.vue"),
                },
                {
                    name: "register",
                    path: "register",
                    component: () =>
                        import ("@/views/user/Register.vue"),
                },
                {
                    name: "resetpassword",
                    path: "resetPassword/:token",
                    component: () =>
                        import ("@/views/user/ResetPassword.vue"),
                },
                {
                    name: "forgetPassword",
                    path: "forget",
                    component: () =>
                        import ("@/views/user/forgetPassword.vue"),
                },
                {
                    name: "video",
                    path: "video",
                    component: () =>
                        import ("@/views/user/Video.vue"),
                },
                {
                    name: "course-detail",
                    path: "course-detail",
                    component: () =>
                        import ("@/views/user/DetailCourse.vue"),
                },
            ],
        },
        {
            path: "/account",
            name: "account",
            redirect: "detail",
            component: () =>
                import ("@/layouts/user-layout/Blanklayout.vue"),
            children: [{
                    name: "detail",
                    path: "detail",
                    component: () =>
                        import ("@/views/user/information/Detail.vue"),
                },
                {
                    name: "address",
                    path: "address",
                    component: () =>
                        import ("@/views/user/information/Address.vue"),
                },
                {
                    name: "certificate",
                    path: "certificate",
                    component: () =>
                        import ("@/views/user/information/Certificate.vue"),
                },
                {
                    name: "mycourse",
                    path: "mycourse",
                    component: () =>
                        import ("@/views/user/information/MyCourse.vue"),
                },
                {
                    name: "paymenthistory",
                    path: "paymenthistory",
                    component: () =>
                        import ("@/views/user/information/PaymentHistory.vue"),
                },
                {
                    name: "verifyUser",
                    path: "verify-user",
                    component: () =>
                        import ("@/views/user/VerifyUser.vue"),
                },
                {
                    name: "transactionBuy",
                    path: "transaction-buy",
                    component: () =>
                        import ("@/views/user/BuyTransaction.vue"),
                },
                {
                    name: "payment",
                    path: "payment",
                    component: () =>
                        import ("@/views/user/Payment.vue"),
                },
                {
                    name: "quiz",
                    path: "quiz",
                    component: () =>
                        import ("@/views/user/Quiz.vue"),
                },
            ],
        },
        //backoffice
        {
            path: "/backoffice",
            component: () =>
                import ("@/layouts/blank-layout/Blanklayout"),
            children: [{
                name: "login",
                path: "",
                component: () =>
                    import ("@/views/backoffice/Login"),
            }, ],
        },
        {
            path: "/backoffice",
            component: () =>
                import ("@/layouts/full-layout/Layout"),
            children: [{
                    name: "backoffice-admin",
                    path: "admin",
                    component: () =>
                        import ("@/views/backoffice/Admin"),
                },
                {
                    name: "backoffice-course",
                    path: "course",
                    component: () =>
                        import ("@/views/backoffice/Course"),
                },
                {
                    name: "backoffice-customer",
                    path: "customer",
                    component: () =>
                        import ("@/views/backoffice/Customer"),
                },
                {
                    name: "backoffice-approve-passport",
                    path: "approve-passport",
                    component: () =>
                        import ("@/views/backoffice/ApprovePassport"),
                },
                {
                    name: "backoffice-banner",
                    path: "banner",
                    component: () =>
                        import ("@/views/backoffice/Banner"),
                },
                {
                    name: "backoffice-transaction",
                    path: "transaction",
                    component: () =>
                        import ("@/views/backoffice/Transaction"),
                },
                {
                    name: "backoffice-header",
                    path: "header",
                    component: () =>
                        import ("@/views/backoffice/Header"),
                },
                {
                    name: "backoffice-condition",
                    path: "condition",
                    component: () =>
                        import ("@/views/backoffice/Condition"),
                },
                {
                    name: "backoffice-profile",
                    path: "my-profile",
                    component: () =>
                        import ("@/views/backoffice/MyProfile"),
                },
                {
                    name: "backoffice-teacher",
                    path: "teacher",
                    component: () =>
                        import ("@/views/backoffice/Teacher"),
                },
                {
                    name: "backoffice-manage-course",
                    path: "backoffice-manage-course/:id",
                    component: () =>
                        import ("@/views/backoffice/ManageCourse"),
                },
                {
                    name: "backoffice-manage-chapter",
                    path: "backoffice-manage-chapter/:lesson_id",
                    component: () =>
                        import ("@/views/backoffice/ManageChapter"),
                },
            ],
        },
        {
            name: "invoice",
            path: "/invoice",
            component: () =>
                import ("@/views/user/Invoice.vue"),
        },

        {
            path: "*",
            component: () =>
                import ("@/views/authentication/Error"),
        },
    ],
});

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        NProgress.start(800);
    }
    next();
});

router.afterEach(() => {
    // Complete the animation of the route progress bar.
    NProgress.done();
});

export default router;