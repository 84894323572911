import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default new Vuex.Store({
  state: {
    Sidebar_drawer: null,
    Customizer_drawer: false,
    SidebarColor: "white", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
    SidebarBg: "",
    navbarColor: "primary",
    setHorizontalLayout: false, // Horizontal layout
    user: null,
    detailCourse: null,
    chapter: null,
    course: null,
    courseTotal: null,
    SaveLoadSurveyCreatorExample: null,
  },
  mutations: {
    SET_NAVBAR_COLOR(state, payload) {
      state.navbarColor = payload;
    },
    SET_LAYOUT(state, payload) {
      state.setHorizontalLayout = payload;
    },
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_DETAIL_COURSE(state, payload) {
      state.detailCourse = payload;
    },
    SET_PAYMENT_MEDTHOD(state, payload) {
      state.detailCourse.paymentMethod = payload;
    },
    SET_EXAM(state, payload) {
      state.chapter = payload;
    },
    SET_COURSE(state, payload) {
      state.course = payload;
    },
    SET_COURSE_TOTAL(state, payload) {
      state.courseTotal = payload;
    },
    SET_INVOICE(state, payload) {
      state.invoice = payload;
    },
  },
  actions: {},
  getters: {
    fetchCourseTotals(state) {
      return state.courseTotal.filter((item) => !item.is_promotion);
    },
  },
  plugins: [vuexLocal.plugin],
});
