<template>
  <v-app
    id="materialpro"
    :class="`${
      !$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'
    }`"
  >
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,200;0,400;0,700;1,200;1,300;1,400;1,700&display=swap");
* {
  font-family: "Poppins", "Prompt" !important;
}
[class^="swal2"] {
  font-family: "Poppins", "Prompt" !important;
}
</style>
