import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import Vuebar from "vuebar";
import "./plugins/base";
// import VueSkycons from "vue-skycons";
// import InstantSearch from "vue-instantsearch";
import VueSweetalert2 from "vue-sweetalert2";
import VueQuillEditor from "vue-quill-editor";

// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";



Vue.use(VuePlyr, {
  plyr: {},
});

Vue.use(VueQuillEditor);
// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2);
// Vue.use(VueSkycons, {
//     color: "#1e88e5",
// });
// Vue.use(InstantSearch);

Vue.config.productionTip = false;
Vue.use(Vuebar);

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
